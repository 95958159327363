const selectorCybotCookiebotDialogPoweredbyImage = $('#CybotCookiebotDialogPoweredbyImage');

// TRIGGER DEFINED FUNCTIONS
defineWidthAndHeightForCybotCookiebotDialogPoweredbyImage();

// DEFINE FUNCTIONS
function defineWidthAndHeightForCybotCookiebotDialogPoweredbyImage() {
  selectorCybotCookiebotDialogPoweredbyImage.attr('height', 32);
  selectorCybotCookiebotDialogPoweredbyImage.attr('width', 42);
}
